import { Language } from '@frond/shared/lib/i18n';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';

import { HomeView } from '../../../modules/questions/views/HomeView';

export async function getStaticProps({ locale }: { locale: Language }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'common',
        'groups',
        'posts',
        'events',
      ])),
    },
    revalidate: 60,
  };
}

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: 'blocking',
  };
}

const HomePage: React.FC = () => {
  return <HomeView />;
};

export default HomePage;
