import { routes, theme } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { NextSeo } from 'next-seo';
import React, { useCallback, useEffect, useState } from 'react';

import {
  PostTypes,
  useHomeFeedLatestContentQuery,
  useHomeFeedQuery,
  useNewMemberPostQuery,
  ViewLevels,
  ViewLocations,
} from '../../../../generated/types-and-hooks';
import { BASE_URL, POSTS_PER_PAGE } from '../../../config/constants';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { useIsViewerAdmin, useViewer } from '../../auth/hooks/useViewer';
import { useContentScroll } from '../../common/hooks/useContentScroll';
import { usePreviousBoolean } from '../../common/hooks/usePreviousBoolean';
import { InfiniteScroll } from '../../common/layout/InfiniteScroll';
import { WelcomeCardFrond } from '../../groups/components/WelcomeCardFrond';
import { IntroduceYourselfCard } from '../../posts/components/IntroduceYourselfCard';
import { PostCard } from '../../posts/components/PostCard';
import { PostViewMutator } from '../../posts/components/PostViewMutator';
import { OverquotaCards } from '../../quotas/components/OverquotaCard';
import { HomeLayout } from '../components/HomeLayout';
import { PostsLoading } from '../components/PostsLoading';
import { QuestionsFeedNewContentToken } from '../components/QuestionsFeedNewContentToken';
import { PostNavContext } from '../hooks/useAnswerNavigation';
import { PostsEmptyView } from './PostsEmptyView';

export const HomeView: React.FC = () => {
  const { viewer, isViewerLoading } = useViewer();
  const previousViewerExists = usePreviousBoolean(!!viewer);
  const [newContentExists, setNewContentExists] = useState(false);
  const { getScrollTop } = useContentScroll();
  const { organization } = useOrganization();
  const isAdmin = useIsViewerAdmin(organization);
  const membership = viewer?.organizationMemberships.find(
    (m) => m.organization.id === organization.id
  );

  const { data: latestData, refetch: refetchLatestData } =
    useHomeFeedLatestContentQuery({
      variables: {
        organizationId: organization.id,
      },
      fetchPolicy: 'cache-and-network',
    });

  const { data: newMemberPostData } = useNewMemberPostQuery({
    variables: {
      organizationId: organization.id,
      userId: viewer?.id || '',
    },
    skip: !viewer,
    context: {
      skip: !viewer,
    },
  });

  const {
    data: feedData,
    loading,
    fetchMore,
    refetch: refetchFeedData,
  } = useHomeFeedQuery({
    variables: {
      organizationId: organization.id,
      first: POSTS_PER_PAGE,
    },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
    context: {
      skip: true, // don't refetch on window refocus
    },
  });

  const handleRefetch = useCallback(() => {
    refetchFeedData();
    refetchLatestData();
  }, [refetchFeedData, refetchLatestData]);

  useEffect(() => {
    // If the viewer has just been set or not loaded when not signed in, and
    // the organization is public, refetch the feed to get the latest content
    // since latestData might only be right at the top of the page
    if (
      !previousViewerExists &&
      (viewer || (!viewer && !isViewerLoading)) &&
      organization.isPublic
    ) {
      handleRefetch();
    }
  }, [
    handleRefetch,
    organization.isPublic,
    previousViewerExists,
    viewer,
    isViewerLoading,
  ]);

  /*
   * Detect new content in the feed
   */
  useEffect(() => {
    const latestCachedNodeCreatedAt =
      feedData?.homeFeed?.edges.reduce<Date | null>((latest, edge) => {
        // Welcome posts aren't chronological
        if (
          edge.node.__typename === 'Post' &&
          edge.node.type === PostTypes.Welcome
        ) {
          return latest;
        }

        const createdAt = new Date(edge.node.createdAt);
        return !latest || createdAt > latest ? createdAt : latest;
      }, null);

    const latestNodeCreatedAt = latestData?.homeFeedLatestItem?.createdAt
      ? new Date(latestData.homeFeedLatestItem.createdAt)
      : null;

    if (!latestCachedNodeCreatedAt || !latestNodeCreatedAt) {
      return;
    }

    if (latestNodeCreatedAt.getTime() === latestCachedNodeCreatedAt.getTime()) {
      setNewContentExists(false);
      return;
    }
    // If there's new content and we're right at the top of the page
    // ie. first load, pull the new questions from the API immediately
    if (getScrollTop() === 0) {
      handleRefetch();
      return;
    }

    // Otherwise, show the token to refresh manually
    setNewContentExists(true);
  }, [latestData, feedData, handleRefetch, getScrollTop]);

  const feedItems = feedData?.homeFeed?.edges.map((edge) => edge.node);

  const renderItems = () => {
    return feedItems?.map((item) => {
      if (item.__typename === 'Post') {
        return (
          <PostViewMutator
            post={item}
            viewLevel={ViewLevels.Detail}
            viewLocation={ViewLocations.Home}
            key={item.id}
            delay={2500}
          >
            <PostCard
              post={item}
              navContext={PostNavContext.FEED}
              maxComments={3}
            />
          </PostViewMutator>
        );
      }

      if (
        item.__typename === 'OrganizationMembership' &&
        !newMemberPostData?.newMemberPost
      ) {
        return <IntroduceYourselfCard variant="feed" key={item.id} />;
      }
    });
  };

  const openGraph = {
    title: `${organization.name} - Frond`,
    url: `${BASE_URL}${routes.groups
      .organization(organization.shortId)
      .feed()}`,
  };

  const showFrondOnboardingCard = !viewer
    ? true
    : isAdmin &&
      membership &&
      membership.isCreator &&
      !membership.hideFrondIntroPost;

  return (
    <HomeLayout>
      <NextSeo
        title={openGraph.title}
        canonical={openGraph.url}
        openGraph={openGraph}
      />
      {!feedItems ? (
        <PostsLoading />
      ) : feedItems.length <= 1 &&
        !showFrondOnboardingCard &&
        !newMemberPostData?.newMemberPost &&
        !organization.enableNewMemberIntroductions ? (
        <PostsEmptyView />
      ) : feedItems.length === 0 ? (
        <PostsEmptyView />
      ) : (
        <x.div spaceY={6} pb={6}>
          <InfiniteScroll
            threshold={100}
            loading={loading}
            hasMore={!!feedData?.homeFeed?.pageInfo?.hasNextPage}
            loadMore={() => {
              feedData &&
                fetchMore({
                  variables: {
                    after: feedData.homeFeed?.pageInfo?.endCursor,
                    first: POSTS_PER_PAGE,
                  },
                });
            }}
          >
            {isAdmin && <OverquotaCards showButton />}
            {showFrondOnboardingCard && membership ? (
              <WelcomeCardFrond membership={membership} />
            ) : null}
            {renderItems()}
          </InfiniteScroll>
        </x.div>
      )}

      <QuestionsFeedNewContentToken
        top={theme.sizes[8]}
        left={{ _: 0, 'lg-2': '23rem' }}
        newContentExists={newContentExists}
        refetch={handleRefetch}
      />
    </HomeLayout>
  );
};
