import { mapValues } from 'lodash';

import { FeatureQuota } from '../../../../generated/graphql-request-api-sdk';
import { useOrganizationQuotaUsageQuery } from '../../../../generated/types-and-hooks';
import { useOrganization } from '../../auth/hooks/useOrganization';

/**
 * Detailed usage data is more expensive and only accessible to community admins.
 * If you just want to look up the quota limits, use useOrganizationEntitlements.
 */
export const useOrganizationQuotaUsage = () => {
  const { organization } = useOrganization();
  const { data, loading, refetch } = useOrganizationQuotaUsageQuery({
    variables: { organizationId: organization.id },
    fetchPolicy: 'cache-and-network',
  });

  const quotas = mapValues(
    FeatureQuota,
    (quota) =>
      data?.organization?.quotaUsage?.find((q) => q.name === quota) || {
        name: quota,
        limit: 0,
        used: 0,
      }
  );

  return {
    loading,
    refetch,
    quotas,
  };
};
