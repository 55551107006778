import { routes } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React from 'react';

import {
  OrganizationMembership,
  OrganizationMembershipOnboardingTasks,
  OrganizationTemplateType,
  refetchViewerQuery,
  useDismissFrondIntroPostMutation,
  useOrganizationRolesQuery,
  useToggleOrganizationMembershipOnboardingTaskMutation,
} from '../../../../generated/types-and-hooks';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { useViewer } from '../../auth/hooks/useViewer';
import { Button } from '../../common/components/Button';
import { Checkbox } from '../../common/components/Checkbox';
import { Emoji } from '../../common/components/Emoji';
import { Heading } from '../../common/components/Heading';
import { Icon } from '../../common/components/Icon';
import { Text } from '../../common/components/Text';
import { useUsers } from '../../common/hooks/useUsers';

type Task = {
  title: string;
  description: string;
  link: {
    label: string;
    href: string;
    isPrimary?: boolean;
  };
  type: OrganizationMembershipOnboardingTasks;
  autoInferred?: boolean;
  isCompleted: boolean;
};

export const WelcomeCardFrond = ({
  membership,
}: {
  membership: Pick<OrganizationMembership, 'id' | 'completedOnboardingTasks'>;
}) => {
  const { t } = useTranslation();
  const { viewer: user } = useViewer();
  const { organization } = useOrganization();
  const [toggleTask] = useToggleOrganizationMembershipOnboardingTaskMutation();
  const [dismissFrondIntroPost] = useDismissFrondIntroPostMutation({
    refetchQueries: [refetchViewerQuery()],
  });
  const { data: organizationRolesData } = useOrganizationRolesQuery({
    variables: {
      organizationId: organization.id,
    },
  });
  const { data: usersData } = useUsers();

  const tasks: Task[] = [
    ...([
      OrganizationTemplateType.BuildTogether,
      OrganizationTemplateType.Work,
      OrganizationTemplateType.Personal,
      OrganizationTemplateType.StartFromScratch,
    ].includes(organization.templateType)
      ? [
          {
            title: t('onboarding_task_activate_trial_title'),
            description: t('onboarding_task_activate_trial_description'),
            link: {
              label: organization?.adminSubscription?.subscriptionPlan
                ? t('edit')
                : t('activate'),
              isPrimary: true,
              href: routes.settings.organization(organization.shortId).plans(),
            },
            type: OrganizationMembershipOnboardingTasks.ActivateTrial,
            autoInferred: true,
            isCompleted: !!organization?.adminSubscription?.subscriptionPlan,
          },
        ]
      : []),

    ...([
      OrganizationTemplateType.ContentCreator,
      OrganizationTemplateType.BuildTogether,
      OrganizationTemplateType.Work,
      OrganizationTemplateType.Personal,
      OrganizationTemplateType.StartFromScratch,
    ].includes(organization.templateType)
      ? [
          {
            title: t('onboarding_task_edit_permissions_title'),
            description: t('onboarding_task_edit_permissions_description'),
            link: {
              label: t('edit'),
              href: routes.settings
                .organization(organization.shortId)
                .permissions(),
            },
            type: OrganizationMembershipOnboardingTasks.EditPermissions,
            isCompleted: membership.completedOnboardingTasks.includes(
              OrganizationMembershipOnboardingTasks.EditPermissions
            ),
          },
        ]
      : []),

    ...([
      OrganizationTemplateType.ContentCreator,
      OrganizationTemplateType.BuildTogether,
      OrganizationTemplateType.ClubOrAlumniGroup,
      OrganizationTemplateType.Work,
      OrganizationTemplateType.StartFromScratch,
    ].includes(organization.templateType)
      ? [
          {
            title: t('onboarding_task_onboarding_title'),
            description: t('onboarding_task_onboarding_description'),
            link: {
              label: t('edit'),
              href: routes.settings
                .organization(organization.shortId)
                .onboarding(),
            },
            type: OrganizationMembershipOnboardingTasks.SetUpNewMemberOnboarding,
            isCompleted: membership.completedOnboardingTasks.includes(
              OrganizationMembershipOnboardingTasks.SetUpNewMemberOnboarding
            ),
          },
        ]
      : []),

    ...([OrganizationTemplateType.ContentCreator].includes(
      organization.templateType
    )
      ? [
          {
            title: t('onboarding_task_paywall_title'),
            description: t('onboarding_task_paywall_description'),
            link: {
              label: t('add'),
              href: routes.settings
                .organization(organization.shortId)
                .paywall(),
            },
            type: OrganizationMembershipOnboardingTasks.SetUpPaywall,
            autoInferred: true,
            isCompleted: !!organization?.memberSubscriptionPlan?.active,
          },
        ]
      : []),

    ...([OrganizationTemplateType.ContentCreator].includes(
      organization.templateType
    )
      ? [
          {
            title: t('onboarding_task_courses_title'),
            description: t('onboarding_task_courses_description'),
            link: {
              label: t('add'),
              href: routes.settings
                .organization(organization.shortId)
                .features(),
            },
            type: OrganizationMembershipOnboardingTasks.AddCourses,
            autoInferred: true,
            isCompleted: !!organization?.hasCoursesEnabled,
          },
        ]
      : []),

    ...([
      OrganizationTemplateType.ClubOrAlumniGroup,
      OrganizationTemplateType.Personal,
    ].includes(organization.templateType)
      ? [
          {
            title: t('onboarding_task_events_title'),
            description: t('onboarding_task_events_description'),
            link: {
              label: t('add'),
              href: routes.settings
                .organization(organization.shortId)
                .features(),
            },
            type: OrganizationMembershipOnboardingTasks.AddEvents,
            autoInferred: true,
            isCompleted: !!organization?.hasEventsEnabled,
          },
        ]
      : []),

    ...([OrganizationTemplateType.Work].includes(organization.templateType)
      ? [
          {
            title: t('onboarding_task_admins_title'),
            description: t('onboarding_task_admins_description'),
            link: {
              label: t('add'),
              href: routes.settings
                .organization(organization.shortId)
                .members(),
            },
            type: OrganizationMembershipOnboardingTasks.AddAdmins,
            autoInferred: true,
            isCompleted:
              !!usersData &&
              usersData.users.edges.filter((u) => {
                return u.node.organizationMembership?.roles.find(
                  (r) => r.isAdmin
                );
              }).length > 1,
          },
        ]
      : []),

    ...([
      OrganizationTemplateType.ClubOrAlumniGroup,
      OrganizationTemplateType.Personal,
      OrganizationTemplateType.StartFromScratch,
    ].includes(organization.templateType)
      ? [
          {
            title: t('onboarding_task_members_title'),
            description: t('onboarding_task_members_description'),
            link: {
              label: t('add'),
              href: routes.settings
                .organization(organization.shortId)
                .members(),
            },
            type: OrganizationMembershipOnboardingTasks.AddMembers,
            autoInferred: true,
            isCompleted: !!usersData && usersData.users.edges.length > 1,
          },
        ]
      : []),

    ...([OrganizationTemplateType.ClubOrAlumniGroup].includes(
      organization.templateType
    )
      ? [
          {
            title: t('onboarding_task_roles_title'),
            description: t('onboarding_task_roles_description'),
            link: {
              label: t('edit'),
              href: routes.settings.organization(organization.shortId).roles(),
            },
            type: OrganizationMembershipOnboardingTasks.CreateRoles,
            autoInferred: true,
            isCompleted:
              !!organizationRolesData &&
              organizationRolesData.organizationRoles.length > 1,
          },
        ]
      : []),

    ...([
      OrganizationTemplateType.ContentCreator,
      OrganizationTemplateType.BuildTogether,
    ].includes(organization.templateType)
      ? [
          {
            title: t('onboarding_task_domain_title'),
            description: t('onboarding_task_domain_description'),
            link: {
              label: t('add'),
              href: routes.settings
                .organization(organization.shortId)
                .customDomain(),
            },
            type: OrganizationMembershipOnboardingTasks.AddDomain,
            autoInferred: true,
            isCompleted: !!organization?.customDomain,
          },
        ]
      : []),
  ];

  return (
    <x.div p={{ _: 5, sm: 6 }} bg="gray.100" borderRadius={{ sm: 'md-lg' }}>
      <x.div spaceY={3} mb={5}>
        <x.div
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <div>
            <Icon name="check" size="12" />
          </div>
          <div>
            <x.button
              bg="gray.200"
              color="gray.400"
              borderRadius="sm"
              py={1}
              px={2}
              border="default"
              borderColor={{ _: 'transparent', hover: `gray.400` }}
              transition
              transitionDuration="fast"
              transitionTimingFunction="ease-out"
              cursor="pointer"
              onClick={() => {
                if (!organization || !user) return;
                dismissFrondIntroPost({
                  variables: {
                    input: {
                      organizationId: organization.id,
                      userId: user.id,
                    },
                  },
                  optimisticResponse: {
                    __typename: 'Mutation',
                    dismissFrondIntroPost: {
                      __typename: 'OrganizationMembership',
                      hideFrondIntroPost: true,
                      id: membership.id,
                    },
                  },
                });
              }}
            >
              <Text variant="sm-semibold">{t('dismiss')}</Text>
            </x.button>
          </div>
        </x.div>

        <Heading variant="lg">{t('your_community_is_up_and_running')}</Heading>
        <Text>
          {t('your_community_is_up_and_running_explanation_part_one')}{' '}
          <Text as="span" variant="md-semibold">
            {organization?.name}
          </Text>
          . {t('your_community_is_up_and_running_explanation_part_two')}
        </Text>
      </x.div>

      <x.div mb={8} p={5} bg="gray.50" spaceY={5} borderRadius="md">
        {tasks.map((task) => {
          const toggle = (checked: boolean) =>
            toggleTask({
              variables: {
                input: {
                  organizationMembershipId: membership.id,
                  task: task.type,
                },
              },
              optimisticResponse: {
                __typename: 'Mutation',
                toggleOrganizationMembershipOnboardingTask: {
                  __typename: 'OrganizationMembership',
                  completedOnboardingTasks: checked
                    ? [...membership.completedOnboardingTasks, task.type]
                    : membership.completedOnboardingTasks.filter(
                        (t) => t !== task.type
                      ),
                  id: membership.id,
                },
              },
            });
          return (
            <x.div key={task.title} display="flex" spaceX={3}>
              <x.div>
                <Checkbox
                  checked={task.isCompleted}
                  onCheckedChange={(checked) => {
                    if (
                      checked === 'indeterminate' ||
                      !checked ||
                      task.autoInferred
                    )
                      return;

                    toggle(checked);
                  }}
                />
              </x.div>
              <x.div flex={1} spaceY={2}>
                <Text
                  variant="md-semibold"
                  color={task.isCompleted ? 'gray.300' : 'gray.500'}
                >
                  {task.title}
                </Text>
                <Text color={task.isCompleted ? 'gray.300' : 'gray.500'}>
                  {task.description}
                </Text>
              </x.div>
              <x.div
                onClick={() => {
                  if (!task.autoInferred && !task.isCompleted) {
                    toggle(true);
                  }
                }}
              >
                <Button
                  href={task.link.href}
                  label={task.link.label}
                  variant={task.link.isPrimary ? 'primary' : 'secondary'}
                />
              </x.div>
            </x.div>
          );
        })}
      </x.div>
      <x.div spaceY={3}>
        <x.div spaceY={1}>
          <Text variant="md-semibold">
            {t('welcome_card_frond_connect_title')}
          </Text>
          <Text>{t('welcome_card_frond_connect_body')}</Text>
        </x.div>
        <x.div display="grid" gridTemplateColumns={{ sm: '1fr 1fr' }} gap={3}>
          <Button
            href="https://frond.com/frond/home" // Hard coding this for QA
            target="_blank"
            boxSizing="border-box"
            variant="secondary"
            label={t('frond_customer_community')}
            leftAlign
            w="full"
            leftElement={
              <x.span lineHeight={1}>
                <Emoji emojiUnicode="🌿" size={15} />
              </x.span>
            }
          />
          <Button
            href={routes.marketing.schedule()}
            target="_blank"
            boxSizing="border-box"
            variant="secondary"
            label={t('schedule_a_call_with_us')}
            leftAlign
            w="full"
            leftElement={
              <x.span lineHeight={1}>
                <Emoji emojiUnicode="☎️" size={15} />
              </x.span>
            }
          />
          <Button
            href="https://frondcom.notion.site/Getting-started-with-Frond-a38b6f6f3ed74f868705745780fc504e?pvs=4"
            target="_blank"
            boxSizing="border-box"
            variant="secondary"
            label={t('view_our_getting_started_guide')}
            leftAlign
            w="full"
            leftElement={
              <x.span lineHeight={1}>
                <Emoji emojiUnicode="🐣" size={15} />
              </x.span>
            }
          />
          <Button
            href={routes.marketing.faq()}
            target="_blank"
            boxSizing="border-box"
            variant="secondary"
            label={t('view_our_faq')}
            leftAlign
            w="full"
            leftElement={
              <x.span lineHeight={1}>
                <Emoji emojiUnicode="🔮" size={15} />
              </x.span>
            }
          />
        </x.div>
      </x.div>
    </x.div>
  );
};
