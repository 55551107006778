import { routes } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { useOrganization } from '../../auth/hooks/useOrganization';
import { Button } from '../../common/components/Button';
import { Text } from '../../common/components/Text';
import { useOrganizationQuotaUsage } from '../../organizations/hooks/useOrganizationQuotaUsage';

/**
 * Overquota cards are shown to admins when they exceed soft limits of their plan
 */
const OverquotaCard: FC<{
  title: string;
  body: string;
  showButton?: boolean;
}> = ({ title, body, showButton }) => {
  const { t } = useTranslation();
  const { organization } = useOrganization();

  return (
    <x.div backgroundColor="gray.50" borderRadius="md-lg" p={4}>
      <x.div
        display="flex"
        flexDirection="column"
        spaceY={2}
        pb={showButton ? 4 : 0}
      >
        <Text variant="md-semibold" color="red.300">
          {title}
        </Text>
        <Text variant="sm">{body}</Text>
      </x.div>
      {showButton && (
        <Button
          variant="secondary"
          label={t('view_plans')}
          href={routes.settings.organization(organization.shortId).plans()}
        />
      )}
    </x.div>
  );
};

const MemberOverquotaCard: React.FC<{
  limit: number;
  showButton?: boolean;
}> = ({ limit, showButton }) => {
  const { t } = useTranslation();
  return (
    <OverquotaCard
      title={t('member_limit_reached')}
      body={t('member_limit_reached_explanation', { limit })}
      showButton={showButton}
    />
  );
};

/**
 * This will figure out which cards to display based on the current quota usage, only admins should see these cards
 */
export const OverquotaCards: React.FC<{
  showButton?: boolean;
  mb?: number;
}> = ({ mb, showButton }) => {
  const { quotas } = useOrganizationQuotaUsage();

  if (quotas.Members.used > quotas.Members.limit) {
    return (
      <x.div mb={mb}>
        <MemberOverquotaCard
          limit={quotas.Members.limit}
          showButton={showButton}
        />
      </x.div>
    );
  }

  return null;
};
