import { SystemProps, x } from '@xstyled/styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { Button } from '../../common/components/Button';
import { Icon } from '../../common/components/Icon';
import { useContentScroll } from '../../common/hooks/useContentScroll';

type QuestionsFeedNewContentTokenProps = {
  newContentExists: boolean;
  refetch: () => void;
} & SystemProps;

export const QuestionsFeedNewContentToken: FC<
  QuestionsFeedNewContentTokenProps
> = ({ newContentExists, refetch, ...system }) => {
  const { t } = useTranslation();
  const { scrollToTop } = useContentScroll();
  return (
    <AnimatePresence>
      {newContentExists && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            delay: 1,
          }}
        >
          <x.div
            position="fixed"
            justifyContent="center"
            pointerEvents="none"
            display="flex"
            left={0}
            right={0}
            top={8}
            {...system}
          >
            <Button
              variant="primary"
              boxShadow="base"
              size="sm"
              label={t('see_latest_updates')}
              pointerEvents="all"
              transition
              transitionDuration="fast"
              leftElement={<Icon name="arrow-up" />}
              onClick={() => {
                refetch();
                scrollToTop();
              }}
            />
          </x.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
