import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';

import { Icon } from '../../common/components/Icon';
import { Text } from '../../common/components/Text';

export const PostsEmptyView = () => {
  const { t } = useTranslation();
  return (
    <x.div
      w="full"
      h="full"
      flex={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
      boxSizing="border-box"
    >
      <x.div
        spaceY={3}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Icon name="send-letter" size="12" />
        <Text variant="md-semibold" color="gray.300">
          {t('no_posts_yet')}
        </Text>
      </x.div>
    </x.div>
  );
};
