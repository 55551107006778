import { theme } from '@frond/shared';
import { CheckboxIndicator } from '@radix-ui/react-checkbox';
import * as RadixCheckbox from '@radix-ui/react-checkbox';
import styled from '@xstyled/styled-components';

import { Icon } from './Icon';

const StyledCheckbox = styled(RadixCheckbox.Root)`
  all: unset;
  width: calc(${theme.sizes[6]} - 2px);
  height: calc(${theme.sizes[6]} - 2px);
  border-radius: sm;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-color: gray.200;
  cursor: pointer;
  transition-property: ${theme.transitions.property.colors};
  transition-duration: faster;

  &:focus {
    box-shadow: 0 0 0 2px black;
  }

  ${(p) =>
    p.disabled
      ? `
        background-color: ${theme.colors.gray[100]};
        border-color: ${theme.colors.gray[200]};
        color: ${theme.colors.gray[200]};
      `
      : `
        &[data-state='checked'] {
          background-color: ${theme.colors.brand[300]};
          border-color: ${theme.colors.brand[300]};
          color: white;
        }
    
        &:hover {
          border-color: ${theme.colors.brand[300]};
        }
    `}
`;

export const Checkbox = (props: RadixCheckbox.CheckboxProps) => {
  return (
    <StyledCheckbox {...props}>
      <CheckboxIndicator
        style={{
          paddingTop: '2px',
        }}
      >
        <Icon name="check-mark" size="4" />
      </CheckboxIndicator>
    </StyledCheckbox>
  );
};
